import { clientInit } from '@jill64/sentry-sveltekit-cloudflare';

const onError = clientInit(
	'https://5c86f0e008c46766ae26655f686857e6@o4506908424470528.ingest.us.sentry.io/4506914130821120',
	{
		sentryOptions: {
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0
		}
	}
);

export const handleError = onError((e, sentryEventId) => {
	console.error(`[${sentryEventId})] Error: ${e}`);
});
