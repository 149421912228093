import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(app)": [~5,[2]],
		"/(app)/about": [13,[2]],
		"/(app)/cart": [~14,[2]],
		"/(app)/(auth)/error": [~6,[2]],
		"/estimate": [~38],
		"/(app)/faq": [~15,[2]],
		"/(app)/(auth)/find-id-complete": [8,[2]],
		"/(app)/(auth)/find-id": [~7,[2]],
		"/(app)/(auth)/find-password-complete": [10,[2]],
		"/(app)/(auth)/find-password": [~9,[2]],
		"/(app)/guide": [16,[2]],
		"/(app)/(auth)/login": [~11,[2]],
		"/(app)/mypage/address": [~17,[2,3]],
		"/(app)/mypage/coin": [~18,[2,3]],
		"/(app)/mypage/coupon": [19,[2,3]],
		"/(app)/mypage/examiner": [~20,[2,3]],
		"/(app)/mypage/info": [~21,[2,3]],
		"/(app)/mypage/order": [~22,[2,3]],
		"/(app)/mypage/order/[orderId]": [~23,[2,3]],
		"/(app)/mypage/qna": [24,[2,3]],
		"/(app)/mypage/workshop": [25,[2,3]],
		"/(app)/notice": [~26,[2]],
		"/(app)/notice/[id]": [~27,[2]],
		"/(app)/order": [~28,[2]],
		"/(app)/policy/email-rejection": [29,[2,4]],
		"/(app)/policy/privacy": [30,[2,4]],
		"/(app)/policy/terms": [31,[2,4]],
		"/(app)/products": [~32,[2]],
		"/(app)/products/completed": [~34,[2]],
		"/(app)/products/[id]": [~33,[2]],
		"/(app)/registration-complete": [35,[2]],
		"/(app)/(auth)/registration": [~12,[2]],
		"/(app)/support": [36,[2]],
		"/(app)/workshop": [37,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';